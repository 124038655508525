import * as React from "react"
import H1 from "../utils/H1";
import H2 from "../utils/H2";
import H3 from "../utils/H3"
import H5 from "../utils/H5";
import Button from "../utils/Button";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "../components/ContactForm";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paragraph from "../utils/Paragraph";
import LoginForm from "../components/LoginForm";

const Login = () => (
    <Layout>
        <Seo title="Login" />
        <div className="h-300px bg-login bg-bottom bg-no-repeat bg-cover relative z-0">
            <div className="overlay absolute w-full h-full top-0 left-0 opacity-80 z-0">

            </div>
            <div className="absolute w-full h-full flex flex-col justify-center items-center z-0">
                <H1 className="text-white bg-none">Sign In</H1>
            </div>
        </div>
        <div className="px-5% py-12 mb-4 z-40 bg-white lg:w-8/12 md:w-10/12 w-11/12 bg-white flex flex-col justify-center items-left about-tile ml-auto mr-auto transform -translate-y-16">
            {/* <H3 className="text-center text-blue font-bold">Sign In</H3> */}
            <LoginForm></LoginForm>
        </div>

    </Layout>
)

export default Login

