import React, { useState, forwardRef } from "react";
import Eye from "../images/eye-icon.svg";
import EyeOff from "../images/eye-off-icon.svg";
import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import { AiOutlineEyeInvisible } from "@react-icons/all-files/ai/AiOutlineEyeInvisible";

const classes = {
	root:
		"bg-lightblue text-black w-full border-blue rounded-lg border-1 h-11 md:h-12 pl-2 placeholder-gray-100 focus:outline-none",
};
const PasswordInput = forwardRef(({
	inputClassName,
	labelKey,
	name,
	errorKey,
	shadow = false,
	...rest
}, ref) => {
	const [show, setShow] = useState(false);

	const rootClassName = classes.root;
	return (
		<div className="block">
			{labelKey && (
				<label
					htmlFor={name}
					className="block text-black font-normal font-lato text-base leading-none mb-3 cursor-pointer"
				>
					{labelKey}
				</label>
			)}
			<div className="relative">
				<input
					id={name}
					name={name}
					type={show ? "text" : "password"}
					ref={ref}
					className={rootClassName}
					autoComplete="off"
					autoCapitalize="off"
					spellCheck="false"
					{...rest}
				/>
				<label
					htmlFor={name}
					className="absolute end-4 top-5 -mt-2 text-gray-500 cursor-pointer"
					onClick={() => setShow((prev) => !prev)}
				>
					{show ? (
						<AiOutlineEye size={25} />
						// <img src="/images/eye-icon.svg" alt="" className="w-6 h-6" />
						// <EyeOff className="w-6 h-6" />
					) : (
						<AiOutlineEyeInvisible size={25} />
						// <img src="/images/eye-off-icon.svg" alt="" className="w-6 h-6" />
						// <Eye className="w-6 h-6" />
					)}
				</label>
			</div>
			{errorKey && <p className="my-2 text-xs text-red-500">{errorKey}</p>}
		</div>
	);
}
);

export default PasswordInput;
