import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import Input from "../components/common/Input"
import PasswordInput from '../utils/password-input';
import Button from '../utils/Button';

const defaultValues = {
    email: "",
};
const ErrorMessage = ({ children }) => (
    <div className="text-base text-red-400 font-lato mt-2" role="alert">
        {children}
    </div>
);
export default function LoginForm() {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
    });
    const onError = (errors, e) => {
        console.log(errors, e)
    };
    const onSubmit = async (data, event) => {
        event.preventDefault();
        setError(null);
        // const { username, password } = data;
        // try {
        //     const userResponse =
        //         await axios
        //             .post(`${apiUrl}/auth/local`, { identifier: username, password: password });
        //     if (userResponse.data.user.id) {
        //         Cookie.set("token", userResponse.data.jwt);
        //         localStorage.setItem('isAuthenticated', true);
        //         dispatch({ type: 'LOGIN', payload: username });
        //         history.push('/diet-form');
        //     }
        // }
        // catch (error) {
        //     console.log(error)
        //     // setError(error)
        //     setError(error.response.data.message[0].messages[0].message)
        // }

        reset()

        setProcessing(false);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="flex flex-col justify-center w-full mt-8"
            noValidate
        >
            <Input
                labelKey="Username"
                type="text"
                {...register("username", {
                    required: "Your Username (required)",
                })}
                errorKey={errors.username?.message}
                inputClassName="mb-2"
            />
            <PasswordInput
                labelKey="Password"
                errorKey={errors.password?.message}
                {...register("password", {
                    required: `You must need to provide your password`,
                })}
                name="password"
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <Button type="submit" variant={"normal"} className={"bg-blue text-white mt-8 ml-auto mr-auto px-12"}>
                {("Submit")}
            </Button>
        </form>
    )
}
